import React, { lazy, Suspense } from 'react'
import { graphql } from 'gatsby'

import { TemplateContextProvider } from 'components/post/TemplateProvider'

const VibemapSearchProvider = lazy(() => import('components/utils/search-context/Provider'))
const VibemapSearchField = lazy(() => import('components/search/Field'))
const VibemapSearchFilterChips = lazy(() => import('components/search/FilterChips'))
const VibemapSearchFilterPicker = lazy(() => import('components/search/FilterPicker'))
const VibemapSearchResultListing = lazy(() => import('components/search/ResultListing'))
const VibemapSearchParameterPersister = lazy(() => import('components/search/ParameterPersister'))

import '../styles/search.scss'

function SearchPage({ location, data, ...props }) {

  const fromPath = location?.state?.fromPath

  return (
    <TemplateContextProvider fromPath={fromPath} tryLocation={true}>
      <main className="page search-page">
        <div className="container">
          <Suspense fallback={<div>Loading</div>}>
            <VibemapSearchProvider
              doAutoSearch={true}
              //storedCities={data.allWpCity.nodes}
              preferStoredCities={false}
            >
              <VibemapSearchParameterPersister />
              <VibemapSearchField autoFocus />
              <VibemapSearchFilterChips />
              <VibemapSearchFilterPicker doFilterSearch />
              <VibemapSearchResultListing />
            </VibemapSearchProvider>
          </Suspense>
        </div>
      </main>
    </TemplateContextProvider>
  )
}

export const pageQuery = graphql`
query {
  allWpCity {
    nodes {
      title
      link
      uri
      cityDetails {
        description
        icon {
          localFile {
            publicURL
          }
        }
      }
    }
  }
}

`

export default SearchPage